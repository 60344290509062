<script setup lang="ts">
import XzSelectPage from '@/components/xz/XzSelectPage.vue';
import XzSelectPageNew from '@/components/xz/XzSelectPageNew.vue';

import { useRouter } from 'vue-router';

import { Haa } from '@haa/fwk-h5/lib/index';
Haa.util().useLifeCycle();
const { openWindow } = Haa.util().useOeReport();

const router = useRouter();
const handleConfirm = () => {
    let result = openWindow({ skip: '0' });
    if (!result) router.replace({ path: '/home' });
}
</script>

<template>
    <div class="xz-select-wrapper">
        <xz-select-page @change="handleConfirm"></xz-select-page>
        <!-- <xz-select-page-new @change="handleConfirm"></xz-select-page-new> -->

        <wap-banner space-key="Guide_banner"></wap-banner>
    </div>
</template>

<style scoped lang="less">
.xz-select-wrapper {
    height: 100vh;
}
</style>
