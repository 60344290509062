<script setup lang="ts">
import DisclaimerPop from "./DisclaimerPop.vue";
import { ref } from "vue";
const appName = ref<string>(APP_NAME);
</script>

<template>
  <div class="icp-wrapper">
    <p class="text">ICP备案/许可证号：粤ICP备2023084125号-1</p>
    <p class="text">{{ appName }}-深圳云创势网络科技有限公司</p>
    <DisclaimerPop :company="'深圳云创势网络科技有限公司'"></DisclaimerPop>
  </div>
</template>

<style scoped lang="less">
.icp-wrapper {
  text-align: center;
  margin: 30px 0;
  font-size: 26px;
  line-height: 30px;
  color: inherit;
  p{
      margin: 0;
  }
  p:nth-of-type(1) {
      margin-bottom: 20px;
  }
}
</style>
